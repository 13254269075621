export default () => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // DataGrid
  profileImg: {
    borderRadius: '50%',
    marginRight: '0.5rem',
  },
  upperSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '0.5rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: '11',
  },
  field: {
    maxWidth: '24.4rem',
  },
  btnReport: {
    marginRight: '1rem',
  },
  textField: {
    margin: '0 0.25rem',
  },
})
