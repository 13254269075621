import React, { useCallback } from 'react'
import { observer } from 'mobx-react'

import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'

import styles from './styles'

const useStyles = makeStyles(styles)

const PhotoProfile = ({ params }) => {
  const { profileImg } = useStyles()
  if (!params.value) return null
  return (
    <img
      className={profileImg}
      src={params.value}
      alt={params.value}
      width="28"
      height="28"
    />
  )
}

const columns = [
  {
    field: 'imageUrl',
    headerName: '',
    width: 50,
    renderCell: (params) => <PhotoProfile params={params} />,
    // to hide photo column label
    headerClassName: 'hideColumnLabel',
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 275,
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 150,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 150,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 200,
    renderCell: (params) => {
      const value = params.value === 'homePickupDriver' ? 'Home pick-up driver' : 'Outlet driver'
      return value
    },
  },
  {
    field: 'outletCode',
    headerName: 'Outlets',
    width: 200,
    renderCell: (params) => (
      <Typography
        style={{
          lineHeight: 'normal',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        }}
      >
        {params.row.branches?.map((item) => item.code).join(', ') || '-'}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
  },
]

const DriverDataGrid = () => {
  const { driverStore, infoSectionStore } = useStores()

  const handlePageChange = useCallback((params) => {
    driverStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    driverStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 0) {
      driverStore.resetQuery()
    }
    if (param.length === 1) {
      driverStore.updateQuery('sortBy', param[0].field, true)
      driverStore.updateQuery('sortDirection', param[0].sort, true)
    }
    return null
  }

  return (
    <CustomDataGrid
      rows={driverStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={driverStore.total}
      pageSize={driverStore.rowsPerPage}
      page={driverStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      loading={driverStore.loading}
      onSortModelChange={onSortModelChange}
    />
  )
}

export default observer(DriverDataGrid)
